import React from 'react';
import Navbar from '../components/Navbar.js';
import Hero from '../components/Hero.js';
import Collection from '../components/Collection.js';
import Footer from "../components/Footer.js";
import HomeTopImg from '../assets/black-marble-patterned-texture-background-marble-thailand-abstract-natural-marble-black-white-design.jpg';
function Products (){
    return(
        <>
            <Navbar/>
            <Hero 
                cName='topdiv'
                heroImg={HomeTopImg}
                title={<>Discover Our Exclusive<br/>Premium Collection</>}
                text='Reveals the exclusive collection of the finest stone textures'
            />
            <Collection/>
            <Footer />
        </>
    )
}

export default Products;