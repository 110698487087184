import React from 'react';
import Navbar from '../components/Navbar.js';
import Hero from '../components/Hero.js';
import BlogList from '../components/BlogList.js';
import Footer from "../components/Footer";
import HomeTopImg from '../assets/black-marble-patterned-texture-background-marble-thailand-abstract-natural-marble-black-white-design.jpg';

function Blogs() {
    return(
        <>
            <Navbar/>
            <Hero 
                cName='topdiv'
                heroImg={HomeTopImg}
                title={<>Insights & Updates<br/>from the Stone Industry</>}
                text="Discover the latest trends, techniques, and stories from the world of natural stone"
            />
            <BlogList />
            <Footer />
        </>
    )
}

export default Blogs;
