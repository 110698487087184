
import React from 'react';
import Navbar from '../components/Navbar.js'
import Hero from '../components/Hero.js';
import Footer from "../components/Footer";
import HomeTopImg from '../assets/black-marble-patterned-texture-background-marble-thailand-abstract-natural-marble-black-white-design.jpg';
import SandstoneData from '../components/SandstoneData.js';
import ContactCTA from '../components/ContactCTA';
function Sandstone (){
    
    return(
        <>
            <Navbar/>
            <Hero 
                cName='topdiv'
                heroImg={HomeTopImg}
                title={<>Sandstone</>}
                text="Desert-born beauty in every grain"
            />
            <SandstoneData />
            <ContactCTA />
            <Footer />
        </>
    )
}

export default Sandstone;