import React from 'react';
import Navbar from '../components/Navbar.js';
import Hero from '../components/Hero.js';
import AboutUs from '../components/AboutUs.js';
import Footer from "../components/Footer";
import RollingFlags from '../components/RollingFlags.js';
import HomeTopImg from '../assets/black-marble-patterned-texture-background-marble-thailand-abstract-natural-marble-black-white-design.jpg';
function About (){
    return(
        <>
            <Navbar/>
            <Hero 
                cName='topdiv'
                heroImg={HomeTopImg}
                title={<>Who we are <br/>&<br/>What we do</>}
                // text='Reveals the exclusive collection of the finest stone textures'
            />
            <AboutUs />
            <section className="global-presence">
                <RollingFlags />
            </section>
            <Footer />
        </>
    )
}

export default About;