// https://stonomy.com/assets/img/gallery/gallery-16.jpg

import React from 'react';
import Navbar from '../components/Navbar.js'
import Hero from '../components/Hero.js';
import AboutUsHome from '../components/AboutUsHome.js';
import Carousel from '../components/Carousel.js';
import CollectionData from '../components/CollectionData.js';
import Footer from "../components/Footer";
import HomeTopImg from '../assets/black-marble-patterned-texture-background-marble-thailand-abstract-natural-marble-black-white-design.jpg';
function Home (){
    
    return(
        <>
            <Navbar/>
            <Hero 
                cName='topdiv'
                heroImg={HomeTopImg}
                title={<>Committed to Superior<br/>Quality & Resultsy</>}
                text='Reveals the exclusive collection of the finest stone textures'
            />
            <AboutUsHome />
            <Carousel items={CollectionData} />
            <Footer />
        </>
    )
}

export default Home;