const CollectionData = [
    {
      image: 'https://picsum.photos/seed/picsum/600/400',
      title: 'Indian Stone',
      description: 'White Marble, Green marble, Black Marble and Makarana Marble etc.',
    },
    {
        image: 'https://picsum.photos/seed/picsum/600/400',
      title: 'Indian Stone',
      description: 'White Marble, Green marble, Black Marble and Makarana Marble etc.',
    },
    {
        image: 'https://picsum.photos/seed/picsum/600/400',
      title: 'Indian Stone',
      description: 'White Marble, Green marble, Black Marble and Makarana Marble etc.',
    },
    {
        image: 'https://picsum.photos/seed/picsum/600/400',
      title: 'Indian Stone',
      description: 'White Marble, Green marble, Black Marble and Makarana Marble etc.',
    },
    {
        image: 'https://picsum.photos/seed/picsum/600/400',
      title: 'Indian Stone',
      description: 'White Marble, Green marble, Black Marble and Makarana Marble etc.',
    },
    {
        image: 'https://picsum.photos/seed/picsum/600/400',
        title: 'Indian Stone',
        description: 'White Marble, Green marble, Black Marble and Makarana Marble etc.',
    },  
    // Add more items as needed
];
  
export default CollectionData;