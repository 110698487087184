export const blogData = [
    {
        id: 1,
        title: "The Art of Marble Selection: A Guide for Architects",
        category: "Design Tips",
        excerpt: "Learn how to select the perfect marble for your architectural projects. From veining patterns to durability considerations...",
        date: "March 15, 2024",
        image: "https://media.istockphoto.com/id/503047952/photo/natural-stone-tiles-marble-granite-samples-palette-stack.jpg?s=612x612&w=0&k=20&c=u4n8G_xouo13QE8OGooVbg1qhmayhLylaaf1enFDZKw="
    },
    {
        id: 2,
        title: "Sustainable Stone Mining: Our Commitment to the Environment",
        category: "Sustainability",
        excerpt: "Discover how modern quarrying techniques are evolving to minimize environmental impact while maintaining stone quality...",
        date: "March 10, 2024",
        image: "https://img.freepik.com/premium-photo/sustainable-mining-practices-restore-vibrant-landscapes-greener-future_994764-111532.jpg"
    },
    {
        id: 3,
        title: "Latest Trends in Stone Finishing Techniques",
        category: "Industry Trends",
        excerpt: "Explore the newest innovations in stone finishing, from traditional polishing to contemporary textured surfaces...",
        date: "March 5, 2024",
        image: "https://cdn.shopify.com/s/files/1/0417/5616/4263/files/different_finishes.jpg?v=1625829331"
    },
    {
        id: 4,
        title: "Historic Stone Architecture: Lessons from the Past",
        category: "History",
        excerpt: "Delve into centuries-old stone building techniques and their relevance in modern architecture. From Roman aqueducts to Gothic cathedrals...",
        date: "February 28, 2024",
        image: "https://images.unsplash.com/photo-1526129318478-62ed807ebdf9"
    },
    {
        id: 5,
        title: "Innovative Stone Installation Methods for Modern Projects",
        category: "Technology",
        excerpt: "Explore cutting-edge installation techniques that are revolutionizing how we work with natural stone in contemporary construction...",
        date: "February 22, 2024",
        image: "https://images.unsplash.com/photo-1581094794329-c8112a89af12"
    },
    {
        id: 6,
        title: "Maintaining Natural Stone: A Complete Care Guide",
        category: "Maintenance",
        excerpt: "Essential tips and best practices for preserving the beauty and integrity of natural stone installations in both residential and commercial settings...",
        date: "February 15, 2024",
        image: "https://images.unsplash.com/photo-1513694203232-719a280e022f"
    },
    {
        id: 7,
        title: "Color Theory in Stone Design: Creating Harmonious Spaces",
        category: "Design Tips",
        excerpt: "Understanding how to work with natural stone colors to create cohesive and aesthetically pleasing architectural designs...",
        date: "February 8, 2024",
        image: "https://www.linearity.io/blog/content/images/2023/06/color-psychology-NewBlogCover.png"
    },
    {
        id: 8,
        title: "Quarry to Installation: The Journey of Natural Stone",
        category: "Industry Insights",
        excerpt: "Follow the fascinating process of how natural stone makes its way from quarries around the world to your project site...",
        date: "February 1, 2024",
        image: "https://www.greatlakesgm.com/wp-content/uploads/2019/06/Quarry-Option-B.jpg"
    },
    {
        id: 9,
        title: "Stone in Modern Interior Design: Breaking Conventions",
        category: "Design Trends",
        excerpt: "How designers are pushing boundaries with unconventional stone applications in contemporary interior spaces...",
        date: "January 25, 2024",
        image: "https://images.unsplash.com/photo-1600585154526-990dced4db0d"
    },
    {
        id: 10,
        title: "Digital Technology in Stone Fabrication",
        category: "Technology",
        excerpt: "How CNC machines, 3D modeling, and digital templating are transforming the stone fabrication industry...",
        date: "January 18, 2024",
        image: "https://edstoneinc.com/wp-content/uploads/2024/01/krotov_studio_Laser_Technology_in_Stone_Fabrication_c961e7d1-4d6b-46f8-ae93-0f04ad1be132.jpg"
    }
]; 