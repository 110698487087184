import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import './ContactFormStyles.css';

function ContactForm() {
  const form = useRef();
  const [status, setStatus] = useState('');

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_9x1cbwd', 'template_653d90j', form.current, {
        publicKey: 'Ew4iujBYjIUcBvN7U',
      })
      .then(
        () => {
          setStatus('Message sent successfully!');
          form.current.reset();
        },
        (error) => {
          setStatus('Failed to send message. Please try again.');
          console.log('FAILED...', error.text);
        },
      );
  };

  const openWhatsApp = () => {
    const phoneNumber = '919414122093'; 
    const message = encodeURIComponent('Hello, I would like to get in touch with you.');
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${message}`;
    window.open(whatsappUrl, '_blank');
  };

  const openWeChat = () => {
    const wechatInfo = "My WeChat ID is: wxid_2uij1nqb6lg22";
    alert(wechatInfo);
    // Alternatively, you could open a modal with a QR code image
    // window.open('URL_TO_YOUR_WECHAT_QR_CODE', '_blank');
  };

  return (
    <div className="form-container">
      <h1>Send a message to us</h1>
      <form ref={form} onSubmit={sendEmail}>
        <input type="text" name="from_name" placeholder="Name" required />
        <input type="email" name="from_email" placeholder="Email" required />
        <input type="text" name="from_subject" placeholder="Subject" required />
        <textarea name="from_message" placeholder="Message" rows="4" required></textarea>
        <button type="submit" className="submit-button">Submit</button>
        {status && <p>{status}</p>}
      </form>
      <div className="social-buttons">
        <button onClick={openWhatsApp} className="social-button whatsapp-button">Contact on WhatsApp</button>
        <button onClick={openWeChat} className="social-button wechat-button">Contact on WeChat</button>
      </div>
    </div>
  );
}

export default ContactForm;