import AboutUsData from "./AboutUsData";
import "./AboutUsStyles.css";
import img1 from "../assets//close-up-black-marble-textured-background.jpg";
import img2 from "../assets/scott-webb-UjupleczBOY-unsplash.jpg";

const SandstoneData = () => {
  return(
    <div className="aboutus">
      <AboutUsData
        className="first-aboutus"
        heading="Heritage and Craftsmanship"
        text="Sandstone is a sedimentary rock composed of sand-size grains of mineral, rock, 
        or organic material. Sandstone has been used since prehistoric times for construction, 
        decorative art works and housewares, and continues to be used. Sandstone is recognised 
        for its natural strength, durability and slip-resistant properties. It has a variety of 
        applications in roofing, flooring, paving, panelling, beams, pillars, doors, window sills 
        and also for swimming pools and bathrooms."
        img1={img1}
        img2={img2}
        />

        <AboutUsData
        className="first-aboutus-reverse"
        heading="The Red Fort in Delhi, India"
        text="The Red Fort in Delhi, India has massive, 2.5-km-long enclosing walls 
        that are made of red sandstone and that's where the monument got its name."
        img1="https://hblimg.mmtcdn.com/content/hubble/img/delhi/mmt/activities/m_activities_delhi_red_fort_l_341_817.jpg"
        img2="https://images.locationscout.net/2016/09/red-fort-delhi-india.jpg?h=1100&q=83"
        />


        <AboutUsData
        className="first-aboutus"
        heading="Lower Antelope Canyon, Arizona"
        text="A natural marvel, red sandstone interior of Lower Antelope Canyon, Arizona."
        img1="https://media.tacdn.com/media/attractions-splice-spp-674x446/0c/09/94/07.jpg"
        img2="https://media.tacdn.com/media/attractions-splice-spp-674x446/07/7f/67/da.jpg"
        />

    </div>
  )
}

export default SandstoneData;