import React from 'react';
import Navbar from '../components/Navbar.js';
import Hero from '../components/Hero.js';
import Footer from "../components/Footer";
import HomeTopImg from '../assets/black-marble-patterned-texture-background-marble-thailand-abstract-natural-marble-black-white-design.jpg';
import ContactCTA from '../components/ContactCTA';
import ProjectGrid from '../components/ProjectGrid';

function Projects() {
    return(
        <>
            <Navbar/>
            <Hero 
                cName='topdiv'
                heroImg={HomeTopImg}
                title={<>Committed to Superior<br/>Quality & Results</>}
                text='Reveals the exclusive collection of the finest stone textures'
            />
            <ProjectGrid />
            <ContactCTA />
            <Footer />
        </>
    )
}

export default Projects;