import React from 'react';
import { useNavigate } from 'react-router-dom';
import './ContactCTA.css';

const ContactCTA = () => {
    const navigate = useNavigate();

    return (
        <section className="contact-cta">
            <div className="cta-container">
                <h2>Ready to Start Your Project?</h2>
                <p>Get in touch with us to discuss your requirements</p>
                <button 
                    className="cta-button"
                    onClick={() => navigate('/contacts')}
                >
                    Contact Us
                </button>
            </div>
        </section>
    );
};

export default ContactCTA; 