import React from 'react';
import '../components/RollingFlags.css'; // You'll need to create this CSS file

import usaFlag from '../assets/flags/usaFlag.png';
import ukFlag from '../assets/flags/ukFlag.png';
import canadaFlag from '../assets/flags/canadaFlag.png';
import australiaFlag from '../assets/flags/australiaFlag.png';
import germanyFlag from '../assets/flags/germanyFlag.png';
import chinaFlag from '../assets/flags/chinaFlag.png';
import franceFlag from '../assets/flags/franceFlag.png';
import greeceFlag from '../assets/flags/greeceFlag.png';
import hongkongFlag from '../assets/flags/hongkongFlag.png';
import omanFlag from '../assets/flags/omanFlag.png';
import qatarFlag from '../assets/flags/qatarFlag.png';
import switzerlandFlag from '../assets/flags/switzerlandFlag.png';
import taiwanFlag from '../assets/flags/taiwanFlag.png';
import turkeyFlag from '../assets/flags/turkeyFlag.png';
import uaeFlag from '../assets/flags/uaeFlag.png';
import vietnamFlag from '../assets/flags/vietnamFlag.png';

// Import more flag images as needed

const flags = [
    { country: 'USA', image: usaFlag },
    { country: 'UK', image: ukFlag },
    { country: 'Canada', image: canadaFlag },
    { country: 'Australia', image: australiaFlag },
    { country: 'Germany', image: germanyFlag },
    { country: 'China', image: chinaFlag },
    { country: 'France', image: franceFlag },
    { country: 'Greece', image: greeceFlag },
    { country: 'Hong Kong', image: hongkongFlag },
    { country: 'Oman', image: omanFlag },
    { country: 'Qatar', image: qatarFlag },
    { country: 'Switzerland', image: switzerlandFlag },
    { country: 'Taiwan', image: taiwanFlag },
    { country: 'Turkey', image: turkeyFlag },
    { country: 'UAE', image: uaeFlag },
    { country: 'Vietnam', image: vietnamFlag },
    

    // Add more countries as needed
];

function RollingFlags() {
    return (
        <div className="rolling-flags">
            <h2>Today, our products are exported to</h2>
            <div className="flags-container">
                {flags.concat(flags).map((flag, index) => (
                    <div key={index} className="flag">
                        <img src={flag.image} alt={`Flag of ${flag.country}`} />
                        <div>{flag.country}</div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default RollingFlags;