import React from 'react';
import Navbar from '../components/Navbar.js'
import Hero from '../components/Hero.js';
import Footer from "../components/Footer";
import HomeTopImg from '../assets/black-marble-patterned-texture-background-marble-thailand-abstract-natural-marble-black-white-design.jpg';
import SlatestoneData from '../components/SlatestoneData.js';
import ContactCTA from '../components/ContactCTA';
function Granite (){
    
    return(
        <>
            <Navbar/>
            <Hero 
                cName='topdiv'
                heroImg={HomeTopImg}
                title={<>Slatestone</>}
                text="Sophisticated surfaces with timeless appeal"
            />
            <SlatestoneData />
            <ContactCTA />
            <Footer />
        </>
    )
}

export default Granite;