import React from 'react';
import './BlogStyles.css';
import { blogData } from './BlogData';

const BlogList = () => {
    return (
        <div className="blog-container">
            <div className="blog-grid">
                {blogData.map((blog, index) => (
                    <div key={index} className="blog-card">
                        <div className="blog-image">
                            <img src={blog.image} alt={blog.title} />
                        </div>
                        <div className="blog-content">
                            <div className="blog-category">{blog.category}</div>
                            <h2>{blog.title}</h2>
                            <p>{blog.excerpt}</p>
                            <div className="blog-meta">
                                <span className="blog-date">{blog.date}</span>
                                <a href={`/blog/${blog.id}`} className="read-more">Read More →</a>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default BlogList; 