import React, { Component } from 'react';
import './NavbarStyles.css';
import MenuItems from './MenuItems.js';

class Navbar extends Component {
  state = { clicked: false, scrolled: false };

  handleScroll = () => {
    if (window.scrollY > 50) {
      this.setState({ scrolled: true });
    } else {
      this.setState({ scrolled: false });
    }
  };

  handleClick = () => {
    this.setState({ clicked: !this.state.clicked });
  };

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  render() {
    return (
      <nav className={`NavbarItems ${this.state.scrolled ? 'scrolled' : ''}`}>
        <h1 className="navbar-logo">STONOMY</h1>
        <div className="menu-icons" onClick={this.handleClick}>
          <i className={this.state.clicked ? 'fas fa-times' : 'fas fa-bars'}></i>
        </div>
        <ul className={this.state.clicked ? 'nav-menu active' : 'nav-menu'}>
          {MenuItems.map((item, index) => {
            return (
              <li key={index} className={item.submenu ? 'nav-item dropdown' : 'nav-item'}>
                <a className="nav-links" href={item.url}>
                  {item.title}
                </a>
                {item.submenu && (
                  <ul className="dropdown-menu">
                    {item.submenu.map((subitem, subindex) => (
                      <li key={subindex}>
                        <a className="dropdown-link" href={subitem.url}>
                          {subitem.title}
                        </a>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            );
          })}
        </ul>
      </nav>
    );
  }
}

export default Navbar;
