const MenuItems = [
    {
        title : "HOME",
        url : "/",
        cNmae : "nav-links"
    },
    {
        title : "PRODUCTS",
        url : "/products",
        cNmae : "nav-links",
        submenu: [
            {
                title: "ARTIFACTS",
                url: "/products/artifacts",
                cName: "dropdown-link"
            },
            {
                title: "MARBLE",
                url: "/products/marble",
                cName: "dropdown-link"
            },
            {
                title: "LIMESTONE",
                url: "/products/limestone",
                cName: "dropdown-link"
            },
            {
                title: "GRANITE",
                url: "/products/granite",
                cName: "dropdown-link"
            },
            {
                title: "SLATESTONE",
                url: "/products/slatestone",
                cName: "dropdown-link"
            },
            {
                title: "SANDSTONE",
                url: "/products/sandstone",
                cName: "dropdown-link"
            }
          ]
    },
    {
        title : "PROJECTS",
        url : "/projects",
        cNmae : "nav-links"
    },
    {
        title : "ABOUT US",
        url : "/about",
        cNmae : "nav-links"
    },
    {
        title : "CONTACT",
        url : "/contacts",
        cNmae : "nav-links"
    },
    {
        title : "BLOGS",
        url : "/blogs",
        cNmae : "nav-links"
    },
]

export default MenuItems;