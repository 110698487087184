import React from 'react';
import Navbar from '../components/Navbar.js'
import Hero from '../components/Hero.js';
import Footer from "../components/Footer";
import HomeTopImg from '../assets/black-marble-patterned-texture-background-marble-thailand-abstract-natural-marble-black-white-design.jpg';
import ArtifactsData from '../components/ArtifactsData.js';
import ContactCTA from '../components/ContactCTA';

function Artifacts (){
    
    return(
        <>
            <Navbar/>
            <Hero 
                cName='topdiv'
                heroImg={HomeTopImg}
                title={<>Artifacts</>}
                text="Timeless elegance carved in stone"
            />
            <ArtifactsData />
            <ContactCTA />
            <Footer />
        </>
    )
}

export default Artifacts;