import AboutUsData from "./AboutUsData";
import "./AboutUsStyles.css";
import img1 from "../assets/mines.jpeg";
import img2 from "../assets/slabes.jpeg";
import img3 from "../assets/granite-cuting.jpeg"
import img4 from "../assets/cnc-cutting.jpeg"

const AboutUs = () => {
  return(
    <div className="aboutus">
      <AboutUsData
        className="first-aboutus"
        heading={<>How it all started..<br/></>}
        text="Bhairav Stone Impex is founded by Mr. B.C. JAIN, FOUNDER & CHAIRMAN, in 1994. The family business 
        is now supported by the 3rd generation under Mr. JAIN'S continued guidance. 
        Through the two decades of hard work and mutually beneficing LONG-TERM 
        BUSINESS Relationships with clients and suppliers, 
        Bhairav Stone Impex is now synonymous to quality and known for sourcing 
        raw material at the best competitive rates in the market."
        img1={img1}
        img2={img2}
        />
      
      <AboutUsData
        className="first-aboutus-reverse"
        heading="Our Specilization.."
        text="We specialize in providing high-quality dimensional rough blocks, 
        slabs, tiles, and custom-cut cobbles tailored to your needs. Our 
        offerings include a wide range of stone finishes such as polished, 
        flamed, sandblasted, bush-hammered, water jet, laputara, antique, 
        leather, and CNC. Each finish is carefully crafted to meet the 
        highest standards of excellence. Whether you're looking for 
        prefab solutions or custom sizes, our expertise ensures precision 
        and durability. Trust us to deliver exceptional stone products 
        that enhance the beauty and functionality of your projects."
        img1={img3}
        img2={img4}
        />
    </div>
  )
}

export default AboutUs;