import React from 'react';
import Navbar from '../components/Navbar.js';
import Hero from '../components/Hero.js';
import ContactForm from "../components/ContactForm.js"
import Footer from "../components/Footer";
import HomeTopImg from '../assets/black-marble-patterned-texture-background-marble-thailand-abstract-natural-marble-black-white-design.jpg';
function Contact (){
    return(
        <>
            <Navbar/>
            <Hero 
                cName='topdiv'
                heroImg={HomeTopImg}
                title={<>If you have any questions<br/> please do not hesitate to<br/>send us a message</>}
            />
            <ContactForm />
            <Footer />
        </>
    )
}

export default Contact;