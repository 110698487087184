import AboutUsData from "./AboutUsData";
import "./AboutUsStyles.css";
import img1 from "../assets//close-up-black-marble-textured-background.jpg";
import img2 from "../assets/scott-webb-UjupleczBOY-unsplash.jpg";

const GraniteData = () => {
  return(
    <div className="aboutus">
      <AboutUsData
        className="first-aboutus"
        heading="Heritage and Craftsmanship"
        text="The word 'granite' comes from the Latin granum, a grain. It is a 
        light-colored igneous rock with grains large enough to be visible with the unaided eye. 
        Granite is the rock most often quarried as a 'dimension stone'. Granite is widely 
        used in making many daily-life objects as it is hard enough to resist abrasion, strong enough 
        to bear significant weight, and inert enough to resist weathering. Granite has been used for 
        thousands of years in both interior and exterior applications. Most common modern day applications are 
        countertops, floor tiles, paving stone, curbing, stair treads, building veneer, and cemetery monuments."
        img1={img1}
        img2={img2}
        />
      
        <AboutUsData
        className="first-aboutus-reverse"
        heading="Mount Rushmore, United States of America"
        text="Mount Rushmore is a sculpture of United States presidents sculpted from a granite outcrop."
        img1="https://npf-prod.imgix.net/uploads/bigstock-Mount-Rushmore-National-Memori-52256071.jpg?auto=compress%2Cformat&fit=max&q=80&w=1600"
        img2="https://cdn.britannica.com/71/226771-138-0801C623/did-you-know-Mount-Rushmore.jpg?w=800&h=450&c=crop"
        />

        <AboutUsData
        className="first-aboutus"
        heading="Brihadeeswarar Temple, India"
        text="The Brihadeeswarar Temple in India is the world's first temple built entirely of granite in the 11th century AD."
        img1="https://dynamic-media-cdn.tripadvisor.com/media/photo-o/17/6a/e5/a7/thanjavur-brihadeeshwara.jpg?w=1200&h=-1&s=1"
        img2="https://upload.wikimedia.org/wikipedia/commons/thumb/9/9e/Gangaikondacholapuram_Temple_4.jpg/640px-Gangaikondacholapuram_Temple_4.jpg"
        />
    </div>
  )
}

export default GraniteData;