import React, { useState } from 'react';
import { ProjectList } from '../data/ProjectList';
import '../styles/Projects.css';

function ProjectGrid() {
    const [filter, setFilter] = useState('all');

    const filteredProjects = filter === 'all' 
        ? ProjectList 
        : ProjectList.filter(project => project.category.toLowerCase() === filter);

    return (
        <section className="projects-section">
            <div className="container">
                <div className="filter-buttons">
                    <button 
                        className={filter === 'all' ? 'active' : ''} 
                        onClick={() => setFilter('all')}
                    >
                        All Projects
                    </button>
                    <button 
                        className={filter === 'residential' ? 'active' : ''} 
                        onClick={() => setFilter('residential')}
                    >
                        Residential
                    </button>
                    <button 
                        className={filter === 'commercial' ? 'active' : ''} 
                        onClick={() => setFilter('commercial')}
                    >
                        Commercial
                    </button>
                </div>

                <div className="projects-grid">
                    {filteredProjects.map((project) => (
                        <div className="project-card" key={project.id}>
                            <div className="project-image">
                                <img src={project.image} alt={project.title} />
                                <div className="project-overlay">
                                    <h3>{project.title}</h3>
                                    <p>{project.description}</p>
                                    <div className="project-details">
                                        <span>{project.year}</span>
                                        <span>{project.category}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}

export default ProjectGrid; 