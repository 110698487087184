import AboutUsData from "./AboutUsData";
import "./AboutUsStyles.css";
import img1 from "../assets//close-up-black-marble-textured-background.jpg";
import img2 from "../assets/scott-webb-UjupleczBOY-unsplash.jpg";
import img3 from "../assets/vertical-shot-beautiful-sandstone-wall-background-wallpaper.jpg"
import img4 from "../assets/orange-rock-surface.jpg"

const AboutUsHome = () => {
  return(
    <div className="aboutus">
      <AboutUsData
        className="first-aboutus"
        heading={<>Bhairav Stone Impex<br/>Largest supplier of Natural Stone<br/>in India.</>}
        text="Your premier destination for exquisite granite, 
        marble, and stone products. With a legacy of excellence, we specialize in 
        sourcing and delivering the finest natural stones to enhance your spaces. 
        Our commitment to quality and customer satisfaction sets us apart in the industry. 
        Discover our diverse range of premium products, including granite, marble, limestone, 
        and more. Each piece is handpicked to ensure unmatched quality and beauty, 
        perfect for transforming both residential and commercial spaces."
        img1={img1}
        img2={img2}
        />
      
      <AboutUsData
        className="first-aboutus-reverse"
        heading="Our Services and Expertise"
        text="At Stonomy, we offer comprehensive services from consultation 
        to custom fabrication and installation. Our team of experts is 
        dedicated to bringing your vision to life with precision and craftsmanship. 
        From the initial design phase to the final installation, we work closely with 
        our clients to ensure every detail is meticulously executed. 
        Our state-of-the-art technology and skilled artisans guarantee a 
        flawless finish that exceeds expectations. Explore our portfolio of signature projects, 
        showcasing the timeless elegance and durability of our stones. 
        Our satisfied customers' testimonials reflect our commitment to excellence and innovation, 
        underscoring the trust and satisfaction we have built over the years."
        img1={img3}
        img2={img4}
        />
    </div>
  )
}

export default AboutUsHome;