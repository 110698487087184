import AboutUsData from "./AboutUsData";
import "./AboutUsStyles.css";
import img1 from "../assets//close-up-black-marble-textured-background.jpg";
import img2 from "../assets/scott-webb-UjupleczBOY-unsplash.jpg";

const SlatestoneData = () => {
  return(
    <div className="aboutus">
      <AboutUsData
        className="first-aboutus"
        heading="Heritage and Craftsmanship"
        text="Slate is a fine-grained, foliated metamorphic rock that is created by the 
        alteration of shale or mudstone by low-grade regional metamorphism. It is popular 
        for a wide variety of uses such as roofing, flooring, and flagging because of its durability, 
        low moisture absorption and attractive appearance. Slate tiles are often used for 
        interior and exterior flooring, stairs, walkways and wall cladding. Historically it has 
        been used for chalkboards, student writing slates, billiard tables, cemetery markers, whetstones, 
        and table tops."
        img1={img1}
        img2={img2}
        />
      
      <AboutUsData
        className="first-aboutus-reverse"
        heading="Saint Leonhard's Church, Frankfurt"
        text="Fine slate tile work, Saint Leonhard's Church, Frankfurt am Main, Germany."
        img1="https://upload.wikimedia.org/wikipedia/commons/thumb/e/e8/St.leonhard-ffm002.jpg/900px-St.leonhard-ffm002.jpg?20080218052103"
        img2="https://upload.wikimedia.org/wikipedia/commons/thumb/a/ab/Frankfurt_Am_Main-Leonhardskirche-Ansicht_vom_Eisernen_Steg-Gegenwart.jpg/520px-Frankfurt_Am_Main-Leonhardskirche-Ansicht_vom_Eisernen_Steg-Gegenwart.jpg"
        />

    </div>
  )
}

export default SlatestoneData;