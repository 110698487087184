import AboutUsData from "./AboutUsData";
import "./AboutUsStyles.css";
import img1 from "../assets//close-up-black-marble-textured-background.jpg";
import img2 from "../assets/scott-webb-UjupleczBOY-unsplash.jpg";
import img3 from "../assets/vertical-shot-beautiful-sandstone-wall-background-wallpaper.jpg"
import img4 from "../assets/orange-rock-surface.jpg"

const ArtifactsData = () => {
  return(
    <div className="aboutus">
      <AboutUsData
        className="first-aboutus"
        heading="Heritage and Craftsmanship"
        text="Artifacts are more than just decorative pieces 
        they are works of art crafted from the finest natural stones. 
        Each artifact is a testament to the rich heritage of stone carving, 
        embodying the intricate details and timeless beauty that have adorned historic 
        monuments for centuries. The skill and precision involved in creating these 
        artifacts ensure that each piece is unique, carrying its own character and story. 
        From ancient temples to modern sculptures, these artifacts can bring a touch of 
        history and sophistication to any space, creating a focal point that captivates and inspires."
        img1={img1}
        img2={img2}
        />
      
      <AboutUsData
        className="first-aboutus-reverse"
        heading="Durability and Timelessness"
        text="The process of crafting our artifacts involves a combination 
        of traditional techniques and modern technology, ensuring the highest 
        quality and durability. Our artisans take great pride in their work, meticulously 
        shaping each piece to perfection. Whether you are looking for a statement piece for 
        your home or a bespoke design for a commercial project, our artifacts offer unparalleled 
        beauty and craftsmanship. Moreover, the use of natural stone in artifacts not only enhances 
        their aesthetic appeal but also ensures their longevity. Unlike other materials, natural stone 
        ages gracefully, developing a patina that adds to its charm and character over time. This 
        makes our artifacts a timeless investment, capable of enhancing the beauty of your space for generations to come."
        img1={img3}
        img2={img4}
        />
    </div>
  )
}

export default ArtifactsData;