import React from 'react';
import Navbar from '../components/Navbar.js'
import Hero from '../components/Hero.js';
import Footer from "../components/Footer";
import HomeTopImg from '../assets/black-marble-patterned-texture-background-marble-thailand-abstract-natural-marble-black-white-design.jpg';
import MarbleData from '../components/MarbleData.js';
import ContactCTA from '../components/ContactCTA';
function Marble (){
    
    return(
        <>
            <Navbar/>
            <Hero 
                cName='topdiv'
                heroImg={HomeTopImg}
                title={<>Marble</>}
                text="Timeless luxury etched in stone"
            />
            <MarbleData />
            <ContactCTA />
            <Footer />
        </>
    )
}

export default Marble;