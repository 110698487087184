import AboutUsData from "./AboutUsData";
import "./AboutUsStyles.css";
import img1 from "../assets//close-up-black-marble-textured-background.jpg";
import img2 from "../assets/scott-webb-UjupleczBOY-unsplash.jpg";

const MarbleData = () => {
  return(
    <div className="aboutus">
      <AboutUsData
        className="first-aboutus"
        heading="Heritage and Craftsmanship"
        text="The word 'marble' is derived from the Ancient Greek, mármaron, 
        meaning crystalline rock, shining stone. Marble is a metamorphic rock 
        that forms when limestone is subjected to the heat and pressure of metamorphism. 
        Marble can be found in multiple colors like while, bluish, gray, pink, yellow, and black. 
        As the favorite medium for Greek and Roman sculptors and architects, marble has become a 
        cultural symbol of tradition and refined taste. In the present age, the most common marble 
        applications are floor tiles, architectural panels, facing stone, window sills, stair treads, 
        columns, and it continues to be a favorite stone for crafting interior and exterior decoratives."
        img1={img1}
        img2={img2}
        />
      
      <AboutUsData
        className="first-aboutus-reverse"
        heading="Taj Mahal, India"
        text="The Taj Mahal in India is entirely clad in marble. An obvious reason why it is called as one of the man-made wonders."
        img1="https://th-thumbnailer.cdn-si-edu.com/KPHiwfaC7pBGVYeQOt3_RF6L4Dw=/800x450/filters:focal(1471x1061:1472x1062)/https://tf-cmsv2-smithsonianmag-media.s3.amazonaws.com/filer/b6/30/b630b48b-7344-4661-9264-186b70531bdc/istock-478831658.jpg"
        img2="https://cdn.i-scmp.com/sites/default/files/images/methode/2016/06/30/ba318890-39de-11e6-9a6a-3421f730b241_1280x720.JPG"
        />

      <AboutUsData
        className="first-aboutus"
        heading="Carlo Franzoni, The Car of History"
        text="Carlo Franzoni's sculptural marble chariot clock, the Car of History, depicting Clio, the Greek muse of history."
        img1="https://live.staticflickr.com/659/31461760264_d81dae7f29_z.jpg"
        img2="https://www.aoc.gov/sites/default/files/styles/standard_lg/public/2023-01/113229_E-1900-Car-History-Clock-Close.jpg.webp?itok=U_vSkzUy"
        />

    </div>
  )
}

export default MarbleData;