import AboutUsData from "./AboutUsData";
import "./AboutUsStyles.css";
import img1 from "../assets//close-up-black-marble-textured-background.jpg";
import img2 from "../assets/scott-webb-UjupleczBOY-unsplash.jpg";

const LimestoneData = () => {
  return(
    <div className="aboutus">
      <AboutUsData
        className="first-aboutus"
        heading="Heritage and Craftsmanship"
        text="Limestone is a sedimentary rock and usually forms in clear, calm, warm, 
        shallow marine waters. It has numerous uses as building material, concrete, 
        filler, soil conditioner, and a popular decorative addition to rock gardens. 
        Limestone is often cut into blocks and slabs of specific dimensions for use in 
        construction and in architecture, especially in Europe and North America. 
        It is used for facing stone, floor tiles, stair treads, window sills, 
        and many other purposes."
        img1={img1}
        img2={img2}
        />
        
        <AboutUsData
        className="first-aboutus-reverse"
        heading="The Jaisalmer Fort, India"
        text="The Jaisalmer Fort, a world UNESCO heritage site, in India. 
        It is built of limestone and sandstone. 
        It is locally referred to as 'Sonar Qila' meaning 'Golden Fort'."
        img1="https://c4.wallpaperflare.com/wallpaper/754/145/320/jaisalmer-fort-wallpaper-preview.jpg"
        img2="https://t4.ftcdn.net/jpg/02/88/10/51/360_F_288105149_rleDz7UQkzXzNwYBzMdd1GrGjCvcni3U.jpg"
        />

        <AboutUsData
        className="first-aboutus"
        heading="Pamukkale, Turkey"
        text="Who would not have heard of Pamukkale in Turkey. 
        It is frequented by tourists who come to see the travertine limestone 
        terrace and enjoy thermal springs."
        img1="https://www.telegraph.co.uk/content/dam/Travel/leadAssets/33/93/pam_3393442a.jpg?imwidth=680"
        img2="https://img.traveltriangle.com/blog/wp-content/uploads/2018/12/Pamukkale-In-Winter-1.jpg"
        />

    </div>
  )
}

export default LimestoneData;