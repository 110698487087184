import React from 'react';
import Navbar from "./components/Navbar";
import {Routes, Route, useLocation} from "react-router-dom";
import "./styles.css"
import Home from "./routes/Home.js";
import Products from "./routes/Products.js";
import Projects from "./routes/Projects.js";
import About from "./routes/About.js";
import Contact from "./routes/Contact.js";
import Artifacts from './routes/Artifacts.js';
import Marble from './routes/Marble.js';
import Limestone from './routes/Limestone.js';
import Granite from './routes/Granite.js';
import Sandstone from './routes/Sandstone.js';
import Slatestone from './routes/Slatestone.js'
import PageTransition from './components/PageTransition.js';
import Blogs from './routes/Blogs.js';

function App() {
  const location = useLocation();

  return (
    <div className="App">
      <PageTransition>
        <Routes location={location} key={location.pathname}>
          <Route path="/" element={<Home/>}/>
          <Route path="/products" element={<Products/>}/>
          <Route path="/projects" element={<Projects/>}/>
          <Route path="/about" element={<About/>}/>
          <Route path="/contacts" element={<Contact/>}/>
          <Route path="/products/artifacts" element={<Artifacts/>}/>
          <Route path="/products/marble" element={<Marble/>}/>
          <Route path="/products/limestone" element={<Limestone/>}/>
          <Route path="/products/granite" element={<Granite/>}/>
          <Route path="/products/slatestone" element={<Slatestone/>}/>
          <Route path="/products/sandstone" element={<Sandstone/>}/>
          <Route path="/blogs" element={<Blogs/>}/>
        </Routes>
      </PageTransition>
      <Navbar />
    </div>
  );
}

export default App;