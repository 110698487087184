import React, { useState } from 'react';
import Button from '../components/Button.js';
import './Collection.css';
import gallery1 from '../assets/gallery/gallery-1.jpg';
import gallery2 from '../assets/gallery/gallery-2.jpg';
import gallery3 from '../assets/gallery/gallery-3.jpg';
import gallery4 from '../assets/gallery/gallery-4.jpg';
import gallery5 from '../assets/gallery/gallery-5.jpg';
import gallery6 from '../assets/gallery/gallery-6.jpg';
import gallery7 from '../assets/gallery/gallery-7.jpg';
import gallery8 from '../assets/gallery/gallery-8.jpg';
import gallery9 from '../assets/gallery/gallery-9.jpg';
import gallery10 from '../assets/gallery/gallery-10.jpg';
import gallery11 from '../assets/gallery/gallery-11.jpg';
import gallery12 from '../assets/gallery/gallery-12.jpg';
import gallery13 from '../assets/gallery/gallery-13.jpg';
import gallery14 from '../assets/gallery/gallery-14.jpg';
import gallery15 from '../assets/gallery/gallery-15.jpg';
import gallery16 from '../assets/gallery/gallery-16.jpg';

const imageCollections = {
  all: [
    gallery1, gallery2, gallery3, gallery4,
    gallery5, gallery6, gallery7, gallery8,
    gallery9, gallery10, gallery11, gallery12,
    gallery13, gallery14, gallery15, gallery16
  ],
  granite: [
    gallery1, gallery2, gallery3
  ],
  marble: [
    gallery4, gallery5, gallery6
  ],
  sandstone: [
    gallery7, gallery8, gallery9
  ],
  limestone: [
    gallery10, gallery11, gallery12
  ],
  quartz: [
    gallery13, gallery14, gallery15
  ],
  dolomite: [
    gallery16, gallery2, gallery3
  ],
};

const collectionUrls = {
  all: '/products',
  granite: '/products/granite',
  marble: '/products/marble',
  sandstone: '/products/sandstone',
  limestone: '/products/limestone',
  quartz: '/products/quartz',
  dolomite: '/products/dolomite',
};

const Collection = () => {
  const [selectedCollection, setSelectedCollection] = useState('all');

  const handleCollectionClick = (collection) => {
    setSelectedCollection(collection);
  };

  const handleLearnMoreClick = () => {
    const url = collectionUrls[selectedCollection];
    window.location.href = url;
  };

  return (
    <div className="collection-container">
      <div className="collection-filter-bar">
        {Object.keys(imageCollections).map((collection) => (
          <div
            key={collection}
            className={`filter-item ${selectedCollection === collection ? 'active' : ''}`}
            onClick={() => handleCollectionClick(collection)}
          >
            {collection.charAt(0).toUpperCase() + collection.slice(1)}
          </div>
        ))}
      </div>
      <div className="collection-images">
        {imageCollections[selectedCollection].map((image, index) => (
          <div key={index} className="image-item">
            <img src={image} alt={`Stone ${index}`} />
          </div>
        ))}
      </div>
      <div className='learn-more-button'>
        <Button text={selectedCollection.charAt(0).toUpperCase() + selectedCollection.slice(1)} onClick={handleLearnMoreClick} />
      </div>
    </div>
  );
};

export default Collection;
