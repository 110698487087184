import "./FooterStyles.css";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  const openWhatsApp = () => {
    const phoneNumber = '919414122093'; 
    const message = encodeURIComponent('Hello, I would like to get in touch with you.');
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${message}`;
    window.open(whatsappUrl, '_blank');
  };

  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-logo">
          <h1>STONOMY</h1>
          <p>Step into elegance.</p>
        </div>
        <div className="footer-links">
          <div className="footer-section">
            <h4>COMPANY</h4>
            <a href="/">About Us</a>
            <a href="/">Products</a>
          </div>
          <div className="footer-section">
            <h4>SUPPORT</h4>
            <a href="/">Blogs</a>
            <a href="/">Projects</a>
          </div>
          <div className="footer-section">
            <h4>NEED HELP?</h4>
            <p>Reach Us</p>
            <p>+91 9950488893</p>
            <p>+91 9414122093</p>
          </div>
          <div className="footer-section">
            <h4>KEEP IN TOUCH</h4>
            <div className="footer-email">
              <input type="email" placeholder="Email" />
              <button type="submit">→</button>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="social-icons">
          <a href="#" onClick={openWhatsApp}><i className="fa-brands fa-whatsapp"></i></a>
          <a href="/"><i className="fa-brands fa-instagram"></i></a>
        </div>
        <div className="footer-info">
          <p>© {currentYear} Stonomy.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;