export const ProjectList = [
    {
        id: 1,
        title: "Luxury Villa Marble Installation",
        category: "Residential",
        image: "https://images.unsplash.com/photo-1600585154526-990dced4db0d?ixlib=rb-4.0.3",
        description: "Complete marble flooring and wall cladding for a 10,000 sq ft Mediterranean villa",
        materials: ["Calacatta Gold Marble", "Nero Marquina"],
        year: "2023"
    },
    {
        id: 2,
        title: "Five-Star Hotel Lobby",
        category: "Commercial",
        image: "https://images.unsplash.com/photo-1618221469555-7f3ad97540d6?ixlib=rb-4.0.3",
        description: "Elegant marble and granite installation for a luxury hotel entrance",
        materials: ["Statuario Marble", "Black Galaxy Granite"],
        year: "2023"
    },
    {
        id: 3,
        title: "Modern Corporate Headquarters",
        category: "Commercial",
        image: "https://images.unsplash.com/photo-1600607686527-6fb886090705?ixlib=rb-4.0.3",
        description: "Premium stone finishing for a corporate office building",
        materials: ["Travertine", "Emperador Dark"],
        year: "2022"
    },
    {
        id: 4,
        title: "Luxury Penthouse Suite",
        category: "Residential",
        image: "https://images.unsplash.com/photo-1600566753190-17f0baa2a6c3?ixlib=rb-4.0.3",
        description: "Complete marble renovation for a high-end penthouse bathroom and kitchen",
        materials: ["Carrara Marble", "Onyx"],
        year: "2023"
    },
    {
        id: 5,
        title: "Boutique Restaurant",
        category: "Commercial",
        image: "https://images.unsplash.com/photo-1600607687939-ce8a6c25118c?ixlib=rb-4.0.3",
        description: "Custom marble bar and flooring installation for an upscale dining establishment",
        materials: ["Verde Guatemala", "Crema Marfil"],
        year: "2023"
    },
    {
        id: 6,
        title: "Mediterranean Estate",
        category: "Residential",
        image: "https://images.unsplash.com/photo-1600585154340-be6161a56a0c?ixlib=rb-4.0.3",
        description: "Luxury pool surround and patio featuring premium Italian marble",
        materials: ["Botticino Marble", "Thassos White"],
        year: "2022"
    }
]; 